<template>
  <v-container class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
        <Header title="Event Manager"/>
        <v-alert v-if="!events || events.length == 0" type="info" prominent outlined border="left" class="ma-4">
          This section of {{tenant.name}} is for managing virtual events and challenges only. 
          <br/>
          Are you a participant of a virtual event? 
          <br/>
          <v-btn color="info" outlined class="mt-4" to="/profile">Take me to my Profile</v-btn>
        </v-alert>
        <v-card-title v-if="eventUtil.newLayout" class="subtitle">Event Manager</v-card-title>

        <div v-if="events">
            <v-card-text class="pb-0">
            <p class="mb-0">Create and manage your own events and challenges! After you have created and published your virtual event others can join the competition.</p>
            </v-card-text>      
            <v-card-text class="pb-0" v-if="events.length == 0">
            <i class="mb-0">You haven't created an event yet.</i>
            </v-card-text>      
            <EventGrid :routeResolver="resolveEventManagerRoute" :items="events" icon="fa-pencil-alt" :minimal="true" class="mx-4 mb-4"></EventGrid>

            <p>
                <v-btn color="primary" :to="{name:'eventmanagerCreate'}" class="ml-4">Create a new event</v-btn>
                <v-btn outlined color="primary" to="/features" class="ml-4">Features</v-btn>
                <v-btn text color="primary" :href="tenant.eventManagerFaqUrl" class="ml-4">FAQs</v-btn>
            </p>
            <br/>
        </div>
        <div v-else>
            <v-skeleton-loader v-if="!user || $store.getters.loading" type="table"></v-skeleton-loader>
        </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
import EventUtil from "@/util/eventUtil";
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    EventGrid,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      profile: null,
      events: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        //var response = await profileService.get(/*full:*/false);
        //this.profile = response.data;
        this.events = (await eventManagerService.getOwnedEvents()).data.data;
      }
    },

    resolveEventManagerRoute(eventId) {
      return {name: 'eventmanagerView', params: {id: eventId}}
    },
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Event Manager', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this);
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

